.root {
    width: 100%;
    display: flex;
}

.actions {
    display: flex;
}

.imageLink {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    min-width: 80px;
}

.variant {
    display: grid;
    gap: var(--spacing-size-one);
    max-width: 100%;
}

.variantRow {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.variantWrapper {
    display: flex;
    gap: var(--spacing-size-one);
    justify-content: space-between;
}

.details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-left: var(--spacing-size-three);
    flex-grow: 1;
}

.price {
    display: flex;
    gap: var(--spacing-size-one);
    justify-content: end;
}

.comparePrice {
    display: flex;
    gap: var(--spacing-size-one);
    text-decoration: line-through;
    color: var(--color-brand-titanium);
    justify-content: end;
}

.size {
    color: var(--color-brand-titanium);
}

.colour {
    color: var(--color-brand-titanium);
}

.meta {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-size-two);
    padding: var(--spacing-size-two) 0;
    align-items: center;
}

.leftAlignedRemoveButton {
    padding: var(--spacing-size-one) var(--spacing-size-two) var(--spacing-size-one) 0 !important;
    padding: var(--spacing-size-one) var(--spacing-size-two) var(--spacing-size-one) 0 !important;
}

.leftAlignedWishListButton {
    padding: var(--spacing-size-one) var(--spacing-size-two) var(--spacing-size-one) 0 !important;
}

.wishlist {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-size-two);
}

.wishlistDesktopAction {
    display: flex;
    gap: var(--spacing-size-two);
    align-items: center;
}

.wishlistMobileAction {
    display: none;
}

.quantity {
    position: relative;
}

.quantitySelect {
    background: none;
    border: none;
    outline: none;
    padding: var(--spacing-size-two) var(--spacing-size-one) var(--spacing-size-two) 0;
    width: 38px;
    color: var(--text-primary);
    -moz-appearance: none;
    -webkit-appearance: none;
}

.quantitySelect::-ms-expand {
    display: none;
}

.quantityVector {
    position: absolute;
    right: 5px;
    top: 9px;
    pointer-events: none;
}

.flip {
    top: 10px;
    transform: rotate(180deg);
}

.errorMessage {
    color: var(--color-brand-Error);
}

@media screen and (max-width: 991px) {
    .root {
        width: 100%;
    }

    .details {
        padding-left: var(--spacing-size-two);
    }

    .variant {
        max-width: 130px;
    }

    .wishlistDesktopAction {
        display: none;
    }

    .wishlistMobileAction {
        display: block;
    }
}
