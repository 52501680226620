.root {
    display: block;
}

.fullWidth {
    width: 100%;
    display: block;
}

.desktopImage {
    display: block;
}

.mobileImage {
    display: none;
}

.adjustAspectRatio {
    aspect-ratio: 4 / 5;
    object-fit: cover;
}

@media screen and (max-width: 767px) {
    .desktopImage {
        display: none;
    }

    .mobileImage {
        display: block;
    }
}
